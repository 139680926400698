import { Controller } from "stimulus"
import { csrfToken, fadeOut } from '../src/js/helpers'

export default class extends Controller {
  static targets = [ "thumbsHolder" ]

  // action
  addThumb(event) {
    this.thumbsHolderTarget.innerHTML = ''

    const files = [...event.target.files]
    files.forEach(el => {
      const img = document.createElement('img')
      img.src = URL.createObjectURL(el)
      img.classList.add('upload-thumb')
      this.thumbsHolderTarget.append(img)
    })
  }

  // action
  deleteImg(event) {
    const button =  event.currentTarget
    const path = button.dataset.path
    fetch(path, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json', // showing 'as JSON' in rails console
        'X-CSRF-Token': csrfToken()
      },
    }).then(res => {
      console.log(res)
      fadeOut(button.previousElementSibling, 1000)
    }).catch(error => console.error("we got an error:", error))
  }

}
