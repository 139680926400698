// reusable function for fetch
export function csrfToken() {
  const csrfToken = document.querySelector("[name='csrf-token']")
  if (csrfToken) {
    return csrfToken.content
  } else {
    return null
  }
}

export const headers = new Headers({
  'Content-Type': 'application/json',
  'Accept': 'application/json', // showing 'as JSON' in rails console
  'X-CSRF-Token': csrfToken()
})

export const fadeOut = (el, duration = 2000, startOpacity = '1') => {
  el.style.opacity = startOpacity
  el.style.transition = `opacity ${duration}ms`
  el.style.opacity = '0'
  setTimeout(() => {
    el.style.display = 'none'
  }, duration)
}

export const fadeIn = (el, duration = 2000, finalOpacity = '1') => {
  el.style.display = 'block'
  el.style.opacity = '0'
  el.style.transition = `opacity ${duration}ms`
  // ensute it in the end
  setTimeout(() => {
    el.style.opacity = finalOpacity
  }, 10)
}
