import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropMenu" ]

  // action
  toggleDrop() {
    this.dropMenuTarget.classList.toggle('drop__menu_open')
  }

}
