import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "prof", 'email' ]

  // action
  checkFree(event) {
    if (event.currentTarget.value === '0') {
      this.profTarget.classList.add('d-none')
    } else {
      this.profTarget.classList.remove('d-none')
    }
  }

  // action
  sendFree() {
    if (this.emailTarget.value === '') {

    }
  }

}
