import { Controller } from "stimulus"
import { fadeOut, fadeIn } from "../src/js/helpers"

export default class extends Controller {
  static targets = ['modalWindow', 'overlay']

  // action
  showModal() {
    fadeIn(this.modalWindowTarget, 500)
    fadeIn(this.overlayTarget, 500, '.5')
  }

  // action
  closeModal() {
    fadeOut(this.modalWindowTarget, 500)
    fadeOut(this.overlayTarget, 500, '.5')
  }
}
