import { Controller } from "stimulus"
import Sortable from 'sortablejs'
import { csrfToken } from "../src/js/helpers"

export default class extends Controller {
  // static targets = ['sortable']

  connect() {
    console.log('sortable connected')
    if (!this.sortable) this.sortableInit()
  }

  sortableInit() {
    this.sortable = new Sortable(this.element, {
      // autoscroll
      forceFallback: true, // disable desktop browser native autoscroll
      scrollSensitivity: 100,
      scrollSpeed: 20,

      animation: 150,
      handle: '.js-drag',
      draggable: '.js-row',
      direction: 'vertical',
      onUpdate: this.setPosition
    })
  }

  setPosition(event) {
    const path = event.item.dataset.changePath

    fetch(path, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        position: event.newIndex + 1
      })
    })
      .then(response => {
        if (!response.ok) throw response
        console.log('position changed')
      })
      .catch(err => console.error('we got an error: ', err))
  }

}
