import { Controller } from "stimulus"
import Splide from '@splidejs/splide'
import '../src/css/modules/splide.scss'

export default class extends Controller {

  initialize() {
    this.initSplide()
  }

  initSplide() {
    this.splide = new Splide('.splide', {
      type: 'loop',
      gap: '5px',
      heightRatio: 0.67,
      width: '100%',
      cover: true,
    }).mount()
  }

}
