import { Controller } from "stimulus"
import Huebee from 'huebee/huebee'
import '../src/css/modules/huebee.scss'

export default class extends Controller {
  static targets = ['pick1', 'pick2']

  initialize() {
    this.initPicker()
  }

  initPicker() {
    [this.pick1Target, this.pick2Target].forEach(el => this.createPicker(el))
  }

  createPicker(el) {
    new Huebee( el, {
      // options
      notation: 'hex',
      saturations: 2,
    })
  }

}
