import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'comCheckbox' ]

  // action
  redirect(event) {
    // Create an instance of the Stripe object with your publishable API key
    const stripe = Stripe(this.data.get('pubsecret'))

    fetch( `${this.data.get('url')}${this.commercial ? '?commercial=true' : ''}`, {
      method: "POST",
    })
      .then(function (response) {
        return response.json()
      })
      .then(function(session) {
        return stripe.redirectToCheckout({ sessionId: session.id })
      })
      .then(function(result) {
        // If redirectToCheckout fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using error.message.
        if (result.error) {
          alert(result.error.message)
        }
      })
      .catch(function (error) {
        console.error("Error:", error)
      })

  }

  get commercial() {
    return this.comCheckboxTarget.checked
  }

}
